<template>
    <el-scrollbar class="add-customer-service">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">实操题</el-breadcrumb-item>
            <el-breadcrumb-item>创建运营数据</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="steps-wrapper">
            <div class="step-item" :class="{current: [1,2,3,4].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">1</div>
                <div class="step-title">创建运营数据</div>
            </div>
            <div class="step-item" :class="{current: [2,3,4].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">2</div>
                <div class="step-title">添加运营数据</div>
            </div>
            <div class="step-item" :class="{current: [3,4].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">3</div>
                <div class="step-title">添加竞争店铺</div>
            </div>
            <div class="step-item" :class="{current: [4].includes(stepActive)}">
                <div class="step-line"></div>
                <div class="step-text">4</div>
                <div class="step-title">创建成功</div>
            </div>
        </div>
        <div class="customer-service-form">
            <OperationBasicData @stepActiveAdd="stepActiveAdd" ref="OperationBasicData" @getEditData="getEditData"
                                v-if="stepActive === 1"/>
            <AddOperationPromoteData @stepActiveAdd="stepActiveAdd" @changeSkip="skipSetting"
                                     ref="AddOperationPromoteData" :editData="editData"
                                     :category_id="stepOneData.category_id"
                                     :module='stepOneData.module_id' :isSkipSetting="isSkipSetting"
                                     v-if="stepActive === 2"/>
            <CompetitionShop @stepActiveAdd="stepActiveAdd" ref="AddOperationPromoteData" :editData="editData"
                             v-if="stepActive === 3"/>
            <div v-if="stepActive === 4" class="creating-success">
                <div class="success-img">
                    <img src="./../../../assets/images/admin/success.png" alt="">
                </div>
                <span>{{ editId ? '保存成功' : '创建成功' }}</span>
                <el-button @click="toList" type="primary">返回列表</el-button>
            </div>
        </div>
        <div class="dialog-footer">
            <el-button v-if="stepActive === 1" @click="toBack">取消</el-button>
            <el-button @click="lastStep" v-if="stepActive !== 1 && stepActive !==4">上一步</el-button>
            <el-button style="margin-left: 30px" type="primary" plain @click="skipSetting"
                       v-if="stepActive == 2 && !isSkipSetting">跳过设置
            </el-button>
            <el-button style="margin-left: 30px" type="primary" @click="getData"
                       v-if="stepActive == 2 && !isSkipSetting">生成数据
            </el-button>
            <el-button style="margin-left: 30px" type="primary"
                       v-if="stepActive !==4 && (stepActive !== 2 || isSkipSetting)" @click="nextStep">下一步
            </el-button>
        </div>
        <div class="progress-modal" v-if="progressVisible">
            <div class="progress-text">
                <div class="desc">{{progressText}}</div>
                <div class="notice">如果进度没有更新，可能任务阻塞，无须担心，后台正在继续处理，完成任务后一样会提示</div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import OperationBasicData from '@/components/admin/practicalExercises/operationPromote/OperationBasicData.vue';
    import AddOperationPromoteData
        from "@/components/admin/practicalExercises/operationPromote/AddOperationPromoteData";
    import CompetitionShop from "@/components/admin/practicalExercises/operationPromote/CompetitionShop";

    import {operationPromote_saveOperationData, operationPromote_info} from '@/utils/apis'
    import {wsUrl} from "@/utils/config";
    import {mapMutations} from "vuex";
    import {randomStr} from "@/utils/common";

    export default {
        name: "AddOperationPromote",
        components: {
            OperationBasicData,
            AddOperationPromoteData,
            CompetitionShop
        },
        data() {
            return {
                //步骤
                stepActive: 1,
                // 第一步的数据
                stepOneData: null,
                // 第二步的数据
                stepTwoData: null,
                // 第三步的数据
                stepThreeData: null,
                editData: null,
                isSkipSetting: false,
                editId: this.$route.query.id || '',
                wsIns: null,
                heartIns: null,
                progressText: "",
                progressVisible: false,
                flag: "",
            }
        },
        mounted() {
            this.flag = randomStr(16)
            this.websocketInit()
        },
        beforeMount() {
            this.clearHeart()
        },
        methods: {
            ...mapMutations(['setOperationBasicData']),
            websocketInit() {
                this.wsIns = new WebSocket(wsUrl())
                this.wsIns.onopen = () => {
                    console.log("connect ok")
                    this.heartInterval()
                    this.bindClient();
                }
                this.wsIns.onmessage = (evt) => {
                    if (!evt.data) {
                        //empty则直接return
                        return
                    }
                    try {
                        const res = JSON.parse(evt.data)
                        if (res.msg) {
                            this.progressText = res.msg
                        }
                        if (res.done) {
                            this.progressVisible = false
                            this.progressText = ""
                            this.stepActive = 4;
                            localStorage.removeItem("isFirst")
                            this.sendMsg({type: "close", flag: this.flag})
                            this.clearHeart()
                        }
                    } catch (e) {
                        console.log(e)
                    }
                }
                this.wsIns.onclose = () => {
                    // this.reConnection()
                }
                this.wsIns.onerror = () => {
                    // this.reConnection()
                }
            },
            reConnection() {
                this.clearHeart();
                this.websocketInit()
            },
            sendMsg(content) {
                if (this.wsIns.readyState === WebSocket.OPEN) {
                    this.wsIns.send(JSON.stringify(content));
                } else {
                    this.websocketInit()
                }
            },
            heartInterval() {
                this.heartIns = setInterval(() => {
                    this.sendMsg({type: "ping"})
                }, 30000);
            },
            bindClient() {
                this.sendMsg({"token": localStorage.getItem("adminToken"), flag: this.flag})
            },
            clearHeart() {
                clearInterval(this.heartIns)
            },
            getEditData(val) {
                this.editData = val
            },
            toBack() {
                this.$router.go(-1);
                this.setOperationBasicData({})
            },
            lastStep() {
                if (this.isSkipSetting && this.stepActive === 2) {
                    this.skipSetting();
                    this.$refs.AddOperationPromoteData.resetErr()
                } else {
                    this.stepActive--
                }
            },
            toList() {
                this.$router.push('/practice/operationPromote')
            },
            // 获取每步的数据
            stepActiveAdd(data) {
                if (this.stepActive === 1) {
                    this.stepOneData = data;
                    this.stepActive++;
                } else if (this.stepActive === 2) {
                    this.stepTwoData = data;
                    this.stepActive++;
                } else if (this.stepActive === 3) {
                    this.stepThreeData = data;
                    this.mainConfirm()
                }
                //
            },
            nextStep() {
                if (this.stepActive === 1) {
                    this.$refs.OperationBasicData.confirmForm()
                } else if (this.stepActive === 2) {
                    this.$refs.AddOperationPromoteData.submitForm()
                } else if (this.stepActive === 3) {
                    this.$refs.AddOperationPromoteData.confirmForm()
                }
            },
            skipSetting() {
                this.isSkipSetting = !this.isSkipSetting;
            },
            getData() {
                this.$refs.AddOperationPromoteData.confirmForm()
            },
            mainConfirm() {
                let params = {
                    title: this.stepOneData.title,
                    module_id: this.stepOneData.module_id,
                    category_id: this.stepOneData.category_id,
                    promote_fee: this.stepOneData.promote_fee,
                    competition_shop: this.stepThreeData.competition_shop,
                    competition_shop_daily_limit: this.stepThreeData.competition_shop_daily_limit,
                    shop_range_low: this.stepThreeData.shop_range_low,
                    shop_range_high: this.stepThreeData.shop_range_high,
                    offer: this.stepThreeData.offer,
                    time: this.stepTwoData.time,
                    location: this.stepTwoData.location,
                    people: this.stepTwoData.people,
                    period: this.stepTwoData.period,
                    resources: [1,3].includes(this.stepOneData.module_id) ? [] : this.stepTwoData.resources,
                    goods: [1,3].includes(this.stepOneData.module_id) ? this.stepTwoData.goods : [],
                }
                if ([1,3].includes(this.stepOneData.module_id)) {
                    params.keywork_id = this.stepTwoData.keywork_id
                }
                if (this.editId) {
                    params.id = this.editId
                }
                operationPromote_saveOperationData(params).then(res => {
                    // this.stepActive++;
                    if (res.code === 200) {
                        if (res.data.user_id) {
                            this.progressVisible = true;
                            this.progressText = "正在整理数据中，请稍等\n\r";
                            const data = {
                                ...res.data,
                                flag: this.flag
                            }
                            this.sendMsg(data)
                        } else {
                            this.stepActive++;
                            localStorage.removeItem('isFirst');
                        }
                    }

                    // localStorage.removeItem('isFirst');
                }).catch(err => {
                    console.error(err)
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .progress-modal {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255, 0.7);
        .progress-text {
            font-size: 24px;
            color: #2DC079;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .notice {
                font-size: 14px;
            }
        }
    }
    .add-customer-service {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        flex-direction: column;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;

            .el-scrollbar__view {
                padding: 0px 20px;
            }
        }

        .dialog-footer {
            text-align: center;
            margin: 100px 0 50px;
        }

        .creating-success {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 120px;

            .success-img {
                width: 240px;
                height: 240px;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            span {
                color: #333333;
                font-size: 24px;
                line-height: 1;
                margin: 32px 0 36px;
            }
        }
    }

</style>
